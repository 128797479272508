.reasons {
  text-align: center;
  margin-top: 10rem;

  @include respond(phone-port) {
    width: 100%;
  }
  @include respond(iphone-5) {
    width: 85%;
  }

  &__grid-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 9rem;
    justify-content: center;

    @include respond(phone-port) {
      flex-direction: column;
    }

    .grid-item {
      &:nth-child(even) {
        margin-left: 1rem;
      }
      &:nth-child(odd) {
        margin-right: 1rem;
      }
    }
  }
}
