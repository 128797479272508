.testimonials {
  text-align: center;
  margin: 6rem auto;

  @include respond(phone-port) {
    width: 100%;
    margin-top: 0;
  }

  &__title,
  &__text {
    @include respond(phone-port) {
      width: 90%;
      margin: 0 auto;
    }
  }

  &__title {
    margin-bottom: 0;

    @include respond(phone-port) {
      margin-bottom: 1rem;
    }
  }

  &__text {
    margin-bottom: 1rem;
  }

  .video-thumb__image-container {
    margin-top: 4rem;
  }
}
