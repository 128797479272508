.goals {
  text-align: center;

  @include respond(phone-port) {
    width: 100%;
  }

  &__icon {
    @include respond(phone-port) {
      width: 8rem;
    }
  }

  &__title,
  &__subtitle,
  &__text {
    @include respond(phone-port) {
      width: 90%;
      margin: 0 auto;
    }
  }
  &__subtitle {
    margin-bottom: 6rem;
    font-family: $title-font;
  }

  &__image-container {
    position: relative;
    text-align: left;
  }

  &__image {
    width: 100%;

    @include respond(phone-port) {
      height: 25rem;
      object-fit: cover;
    }
  }

  &-thumb {
    &__text-container {
      font-family: $title-font;
      position: absolute;
      bottom: 3rem;
      left: 3rem;
      font-weight: 400;
    }

    &__name {
      font-size: $small-title;
      color: $white;
    }

    &__position {
      color: $color-secondary;
      font-size: $big-text;
    }
  }

  &__text {
    text-align: center;
    margin-top: 4rem;
  }
}

#root > div > section.goals > div > object {
  width: min-content;
}
