.d-none {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}

.active {
  color: $white !important;
  padding: 0 0 2rem 0 !important;
  border-bottom: 2px solid $white;
  text-decoration: none;
}

.color-secondary {
  color: $color-secondary !important;
}

.light-primary {
  color: $color-primary-light !important;
}

.image-bw {
  filter: grayscale(100%);
  opacity: 0.6;
}

.lead {
  font-family: $title-font;
}

.hide-mobile {
  @include respond(phone-land) {
    display: none;
  }
}

.hide-desktop {
  display: none;

  @include respond(phone-land) {
    display: block;
  }
}
