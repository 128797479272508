.btn-secondary {
  color: $white;
  border: 1px solid $white;
  padding: 2rem 5rem;
  font-weight: $bold;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  font-size: $big-text;

  &:hover {
    background-color: $white;
    color: $color-secondary;
    transform: translateY(-3px);
  }
}
