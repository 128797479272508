/*
=============================
$breakpoint argument choices
=============================
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > General layout + grid > page layout > components

1em = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == iphone-5 {
    @media only screen and (max-width: 21.875em) {
      @content;
    } // 350px
  }

  @if $breakpoint == phone-port {
    @media only screen and (max-width: 28.125em) {
      @content;
    } // 450px
  }

  @if $breakpoint == phone-land {
    @media only screen and (max-width: 51.25em) {
      @content;
    } // 820px
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } // 900px
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } // 1200px
  }

  @if $breakpoint == laptop {
    @media only screen and (max-width: 102.18em) {
      @content;
    } // 1635px
  }

  @if $breakpoint == big-laptop {
    @media only screen and (max-width: 115em) {
      @content;
    } // 1740px
  }

  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}
