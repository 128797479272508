.team-header > .header__image-container > object {
  transform: translate(-50%, -15%);

  @include respond(phone-port) {
    transform: translate(-50%, 8%);
  }
}

.video-thumb {
  position: relative;

  &__thumbnail {
    width: 100%;
    position: relative;

    @include respond(phone-port) {
      height: 25rem;
      object-position: center;
      object-fit: cover;
    }
  }

  &__play-icon-container {
    cursor: pointer;
  }

  &__play-icon {
    width: 22%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    @include respond(phone-port) {
      width: min-content;
      top: 54%;
      //background-color: chocolate;
      position: absolute;
      bottom: 0;
    }

    embed {
      @include respond(phone-port) {
        width: 80px;
      }
    }

    &:hover {
      cursor: pointer;
      transform: translate(-50%, -50%) scale(1.1);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      background-color: transparent;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  &__text-container {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    text-align: left;
  }

  &__name,
  &__position {
    font-weight: bold;
  }

  &__name {
    color: $white;
  }

  &__position {
    color: $color-secondary-light;
  }
}

.social-caring
  > div
  > div.video-thumb__text-container
  > span.video-thumb__position.lead {
  @include respond(phone-port) {
    display: none;
  }
}

// .header--home > .header__image-container > object {
//   bottom: -5%;
// }
