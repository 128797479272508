.social-media-icons {
  display: flex;
  justify-content: space-around;

  .social-media-icon {
    display: inline-block;
    transition: all 0.3s;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}
