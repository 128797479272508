.numbers-card {
  background-color: $color-secondary;
  color: $white;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 25rem;
    width: $section-width;
    margin: 0 auto;

    @include respond(laptop) {
      justify-content: space-between;
    }
    @include respond(tab-land) {
      width: 100%;
      justify-content: center;
      gap: 5rem;
    }
    @include respond(phone-port) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: initial;
      padding: 4rem 0;
      text-align: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin: 1rem;

    @include respond(phone-port) {
      width: 50%;
    }

    &--title {
      margin: 0;
      padding: 0;
    }
    &--number {
      font-size: 4rem;
      margin: 0;
      padding: 0;
    }
  }
}
