.btn-primary {
  color: $white;
  background-color: $color-secondary;
  padding: 1rem 5rem;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  min-width: max-content;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 4px 7px 15px 0px #8c8c8c;
    cursor: pointer;
  }
}
