html {
  font-size: 10px;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $body-font;
  color: $color-primary-light;
  font-size: $basic-font-size;
  font-weight: 200;

  @include respond(phone-land) {
    font-size: $mobile-font-size;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
  font-size: $title-font-size;
  font-weight: bold;
  color: $color-primary;

  @include respond(phone-land) {
    font-size: $mobile-title-font-size;
  }
}

p {
  letter-spacing: 0.1rem;
  line-height: 1.2;
}

section {
  width: $section-width;
  margin: 5rem auto;

  @include respond(laptop) {
    width: $section-width-laptop;
  }
}

a {
  text-decoration: none;
  color: $color-primary-light;
  font-family: $title-font;
}
