.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65vh;

  @include respond(tab-land) {
    height: 70vh;
    transform: translateY(-4rem);
  }
  @include respond(tab-port) {
    flex-direction: column-reverse;
    height: max-content;
    transform: translateY(-4rem);
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 8rem;
  }
  @include respond(iphone-5) {
    margin-top: 14rem;
    margin-bottom: 10rem;
  }

  .btn-primary {
    @include respond(phone-port) {
      margin-bottom: 0;
    }
  }

  &--home {
    margin-top: 0;
    @include respond(tab-land) {
      height: 50vh;
      transform: translateY(0);
      margin-top: 5rem;
    }
    @include respond(tab-port) {
      height: max-content;
      transform: translateY(0);
      margin-top: 5rem;
      padding-top: 0 !important;
    }

    @include respond(phone-land) {
      padding: 0;
      margin-bottom: 4rem;
      margin-top: 0;
    }

    .btn-primary {
      margin-top: 2rem;

      @include respond(phone-port) {
        margin-bottom: 4rem;
      }
    }
  }

  &--company {
    @include respond(phone-port) {
      margin-bottom: 0;
    }
    @include respond(iphone-5) {
      margin-top: 8rem;
    }
    .header__image-container {
      width: 100%;

      @include respond(phone-port) {
        margin-bottom: 3rem;
      }
    }
  }

  &--training {
    margin-top: 0;

    @include respond(phone-land) {
      padding: 20rem 0 5rem 0;
    }
    @include respond(phone-port) {
      padding-top: 15rem;
      padding-bottom: 4rem;
      margin-top: -10rem;
      margin-bottom: 0;
    }
    @include respond(iphone-5) {
      margin-top: -10rem;
      padding-top: 28rem;
      padding-bottom: 10rem;
    }

    .header__image-container {
      transform: translateY(125px);

      @include respond(tab-land) {
        transform: translateY(20rem);
      }
      @include respond(tab-port) {
        transform: translateY(0);
      }
    }
  }

  &__text-container {
    margin-right: 0;
    width: 100%;

    @include respond(tab-port) {
      margin-right: 1rem;
    }
    @include respond(phone-port) {
      order: 2;
    }
  }

  &__intro {
    font-size: 2.8rem;

    @include respond(phone-land) {
      font-size: 2.5rem;
    }
  }

  &__title {
    font-size: 5rem;
    font-weight: 400;
    margin-right: 4rem;

    @include respond(tab-land) {
      font-size: 4.5rem;
    }
    @include respond(phone-land) {
      font-size: 3rem;
      margin-right: 0;
    }
  }

  &__paragraph {
    width: 70%;
    text-align: left;

    &:nth-child(3) {
      margin-bottom: 4rem;
    }

    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }
    @include respond(phone-port) {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    order: 1;
    border-radius: 5px;
    position: relative;

    &-container {
      width: 100%;
      position: relative;

      @include respond(phone-port) {
        order: 1;
      }
    }
  }

  &__logo {
    display: none;

    @include respond(tab-port) {
      display: flex;
      justify-content: center;
      padding-top: 3rem;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

.team-header {
  margin-top: 6rem;

  @include respond(phone-port) {
    margin-bottom: 0;
  }

  .header__text-container {
    p {
      margin-bottom: 2.5rem;
    }
  }
}
