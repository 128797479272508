// COLORS
$color-primary: #303134;
$color-primary-light: #6d6e71;
$color-secondary: #0075d5;
$color-secondary-light: #75befa;
$light-grey: #e2e2e2;
$footer-color: #f7f8f9;
$black: #000000;
$white: #ffffff;

//FONT FAMILY
$title-font: "Open Sans", sans-serif;
$body-font: "Lora", serif;
$bold: 600;

// FONT-SIZES
$basic-font-size: 1.7rem;
$mobile-font-size: 1.5rem;

$title-font-size: 3.5rem;
$mobile-title-font-size: 3rem;
$small-title: 2.5rem;
$big-text: 2rem;

$section-width: 60%;
$section-width-laptop: 80%;

$big-icon: 12rem;
