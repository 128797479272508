.footer {
  background-color: $footer-color;
  padding: 8rem 0;
  color: $color-primary;

  @include respond(phone-land) {
    font-size: $big-text;
  }

  &__top {
    font-family: $title-font;
  }
  &__container {
    width: $section-width;
    margin: 0 auto;
  }

  &__columns-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 6rem;

    @include respond(tab-port) {
      justify-content: center;
      flex-wrap: wrap;
      gap: 3rem;
    }
  }

  &__column {
    width: 33.3%;

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__small-title {
    font-size: $small-title;
  }

  &__items-container {
    display: flex;
    flex-direction: column;
  }

  &__link {
    color: $color-primary;
    transition: transform 0.3s ease-in-out;
    display: inline-block;

    &:hover {
      transform: scale(1.2);
    }
  }

  .divider {
    width: 100%;
  }
}
