.grid-vertical-box {
  text-align: center;

  &__title {
    font-size: 2.5rem;
  }

  &__text {
    text-align: left;
  }

  &__image {
    height: $big-icon;

    @include respond(phone-port) {
      height: 20%;
    }
  }
}
