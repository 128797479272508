.social-caring {
  text-align: center;
  margin-top: 8rem;

  @include respond(phone-port) {
    width: 100%;
  }

  &__title,
  &__subtitle {
    width: 85%;
    margin: 0 auto;
  }

  &__title {
    @include respond(phone-port) {
      margin-bottom: 2rem;
    }
    &--2 {
      margin-top: 6rem;
      margin-bottom: 4rem;

      @include respond(phone-port) {
        width: 85%;
        margin: 6rem auto 2rem auto;
      }
    }
  }

  &__subtitle {
    margin-bottom: 8rem;

    @include respond(phone-port) {
      width: 85%;
    }
  }

  &__description {
    text-align: left;

    @include respond(phone-port) {
      width: 85%;
      margin: 0 auto;
    }
  }
}
