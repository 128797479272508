.features {
  display: flex;
  gap: 3rem;

  @include respond(phone-port) {
    flex-wrap: wrap;
    gap: 0;
    margin-top: 0;
  }
}
