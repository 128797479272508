.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: $color-secondary;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;

    .navigation__item {
      color: $white;
      font-size: 3.5rem;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &--active {
        color: $color-primary;
      }
    }
  }
}
