.form {
  &__input-container {
    display: flex;
    flex-direction: column-reverse;
  }

  &__text-input,
  &__textarea {
    padding: 1.5rem 1.8rem;
    font-size: 1.6rem;
    background-color: #fafafa;
    border: none;
    outline: none;
    font-family: $body-font;

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-family: $body-font;
    }

    &:focus,
    &:active {
      border: 1px solid $color-secondary;
      outline: none;
    }
  }

  &__text-input:placeholder-shown + &__label,
  &__textarea:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(4rem);
  }

  &__label {
    margin: 0 0 0.3rem 1.8rem;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s;
  }

  &__top-container {
    display: grid;
    grid-template-columns: repeat(2, 35rem);
    gap: 4rem;

    @include respond(tab-land) {
      grid-template-columns: repeat(2, 50%);
    }
    @include respond(phone-port) {
      grid-template-columns: 100%;
      gap: 3rem;
    }
  }

  &__subtitle {
    margin: 4rem 0;
  }

  &__checkbox-top-container {
    display: flex;
    flex-direction: column;
  }

  &__checkbox-container {
    @include respond(phone-port) {
      display: flex;
      align-items: center;
    }
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &--privacy {
      margin-top: 1.5rem;
    }
  }

  &__checkbox {
    display: none;
    &-label {
      cursor: pointer;
      position: relative;
      padding-left: 4rem;

      &--privacy {
        font-size: 1.2rem;
        width: 100%;
        display: flex;

        .form__checkbox--span {
          display: block;

          @include respond(tab-land) {
            margin-top: 0;
          }
        }
      }
    }

    &--span {
      height: 3rem;
      width: 3rem;
      border: 2px solid #c4c4c4;
      border-radius: 5px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: -0.5rem;

      @include respond(phone-port) {
        top: 0;
      }

      &--fill {
        display: block;
        height: 2rem;
        width: 2rem;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($color-secondary, 0.5);
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
  }

  &__bottom-container {
    margin-top: 4rem;
    width: 60%;

    @include respond(tab-land) {
      width: 100%;
    }
    @include respond(phone-port) {
      display: flex;
      flex-direction: column;
    }

    &--input {
      display: flex;
      gap: 3rem;
      margin-bottom: 3rem;

      @include respond(phone-port) {
        flex-wrap: wrap;
      }

      .form__input-container {
        width: 50%;

        @include respond(phone-port) {
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }
  }

  &__submit {
    margin-top: 5rem;
    outline: none;
    border: none;
    padding: 3rem 10rem 3rem 10rem !important;
    background-color: $color-secondary;
    color: white;
    font-family: $title-font;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      transform: translateY(-3px);
    }
  }

  &__promotion-label {
    transform: translateY(-1rem);
  }

  &__privacy-label--text {
    transform: translateY(0.25rem);
  }
}

#subject,
#message {
  margin-bottom: 2rem;
}
