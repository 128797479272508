.reasons {
  @include respond(iphone-5) {
    width: 100%;
  }

  @include respond(phone-port) {
    margin-top: 0;
  }

  &__title,
  &__text,
  &__subtitle {
    @include respond(phone-port) {
      width: 90%;
      margin: 0 auto;
    }
  }

  &__title {
    @include respond(phone-port) {
      margin-bottom: 2rem;
    }
  }

  .quote-card {
    text-align: left;
    width: 100%;

    &__card {
      height: 43rem;
      width: 90%;
      padding: 4rem;
      box-sizing: border-box;
      margin: 0 auto;

      @include respond(phone-land) {
        height: max-content;
      }
      @include respond(phone-port) {
        height: max-content;
        width: 100%;
      }
    }

    &__text {
      margin: 0 auto;
      width: 90%;
      margin-top: 3rem;
      text-align: center;
    }

    &__author {
    }
  }
}
