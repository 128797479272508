.company-closer {
  text-align: center;
  margin: 8rem auto;

  &__title {
    margin-bottom: 6rem;
  }
  &__image {
    width: 70%;
    margin-bottom: 4rem;

    @include respond(phone-port) {
      width: 90%;
    }
  }
  &__text {
    margin-bottom: 2.5rem;
    font-family: $title-font;
  }
}
