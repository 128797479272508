.training-card {
  background-color: $color-secondary;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27rem;

  @include respond(phone-port) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4rem 4rem 8rem 4rem;
    margin: 0;
    height: max-content;
  }

  &__title {
    color: $white;
    font-weight: 400;
    letter-spacing: 2px;
    margin-right: 6rem;

    @include respond(tab-port) {
      margin-right: 3rem;
      font-size: $small-title;
    }
    @include respond(phone-port) {
      margin-right: 0;
      font-size: $small-title;
    }
  }
}
