.training-header-container {
  background-color: $footer-color;
  padding-bottom: 2rem;

  section {
    .header__text-container {
      p {
        @include respond(phone-port) {
          margin-bottom: 3rem;
        }
      }
    }
  }
}

//#root > div > div.training-header-container > section > div.header__text-container > p
