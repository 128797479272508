.super-hero {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 18rem;

  &__container {
    background-color: $footer-color;

    @include respond(phone-port) {
    }
  }

  &__intro {
    @include respond(phone-port) {
      width: 200%;
    }
  }

  &__slogan {
    font-weight: $bold;
    font-size: $big-text;
  }

  &__super-hero {
    width: 38%;
    transform: translate(-10rem, 10rem);

    @include respond(tab-port) {
      transform: translate(-10rem, 7rem);
    }
    @include respond(phone-port) {
      width: 20rem;
      transform: translate(0, 0);
    }
    @include respond(iphone-5) {
      width: 50rem;
      transform: translate(0, 8rem);
    }
  }
}
