.leadership {
  margin: 6rem auto 6rem auto;

  &__title {
    text-align: center;
    margin-bottom: 6rem;
  }

  &__card {
    display: flex;

    @include respond(phone-port) {
      flex-direction: column;
    }

    &-text-container {
      margin-top: 3rem;
      margin-left: 5rem;

      @include respond(tab-port) {
        width: 100%;
        margin-left: 0;
      }
      @include respond(phone-port) {
        margin-top: 0;
      }

      &--ceo {
        margin-left: 0;
        margin-right: 5rem;
        @include respond(tab-port) {
          margin-right: 0;
          order: 2;
        }
      }
    }

    &-image {
      width: 50%;
      margin-bottom: 4rem;

      @include respond(laptop) {
        width: 100%;
      }
      @include respond(tab-land) {
        width: 50%;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
        margin-right: 2rem;
        margin-bottom: 0;
      }
      @include respond(tab-port) {
        width: 50%;
        height: auto;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
        margin-right: 2rem;
        margin-bottom: 0;
      }
      @include respond(phone-port) {
        width: 100%;
        margin-bottom: 2rem;
      }
      &--ceo {
        @include respond(phone-port) {
          order: 1;
        }
      }
    }

    &-name {
      text-transform: uppercase;
      font-size: 2rem;
      letter-spacing: 1px;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &-position {
      color: $color-secondary;
      font-family: $title-font;
    }
  }

  .divider {
    width: 100%;
    margin: 6rem 0;
  }
}
