.what-we-need {
  text-align: center;

  &__container {
    width: 100%;
    background-color: $footer-color;
    padding-bottom: 1rem;
    padding-top: 4rem;
    margin-top: 6rem;
  }
}
