.training-testimonials {
  text-align: center;
  margin-bottom: 8rem;

  @include respond(phone-port) {
    width: 100%;
  }

  &__title,
  &__intro {
    @include respond(phone-port) {
      width: 90%;
      margin: 0 auto;
    }
  }

  &__image {
    margin: 6rem 0;

    @include respond(tab-port) {
      width: 90%;
      margin: 6rem auto;
    }
  }

  &__title {
    @include respond(phone-port) {
      margin-bottom: 1.5rem;
    }
  }

  &__intro {
    margin-bottom: 6rem;
    font-family: $title-font;
  }
}
