.stories {
  background-color: $footer-color;

  &__container {
    padding: 6rem 0;
  }
  &__title {
    text-align: center;
  }
  &__subtitle {
    text-align: center;
    margin-bottom: 6rem;
    font-family: $title-font;
  }
  &__cards-container {
    display: flex;
    gap: 3rem;

    @include respond(phone-port) {
      flex-direction: column;
    }
  }
  &__personnel {
    &-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 6rem;
    }
    &-image {
      width: 100%;
      margin-bottom: 2rem;
    }
    &-name {
      font-size: 2rem;
      letter-spacing: 1px;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &-position {
      color: $color-secondary;
      font-family: $title-font;
    }
    &-bio {
    }
  }

  .video-thumb {
    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
}
