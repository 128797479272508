.video-player {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 5rem;
    right: 10rem;
    width: 4rem;
    transition: transform 0.3s ease-in-out;

    @include respond(phone-land) {
      top: 3rem;
      right: 5rem;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  &__iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width: 70%;
  }

  &__iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 84rem;
    height: 47.2rem;
    border: 0;

    @include respond(tab-port) {
      width: 550px;
      height: 340px;
    }
  }
}
