.closer {
  text-align: center;
  margin-bottom: 10rem;
  &__title {
  }

  &__image {
    @include respond(phone-land) {
      width: 100%;
    }
    @include respond(phone-port) {
      width: 100%;
    }

    svg {
      width: 100%;
    }
  }

  &__subtitle {
    font-size: 2rem;
    color: $color-primary-light;
    font-weight: 400;
    margin-bottom: 6rem;
  }
}
