.quote-card {
  @include respond(phone-port) {
    width: 100%;
  }

  &__card {
    background-color: $color-secondary;
    padding: 4rem;
    color: $white;
    height: 30rem;
    position: relative;
    border-radius: 10px;

    @include respond(tab-port) {
      height: 33rem;
    }
    @include respond(phone-land) {
      height: max-content;
    }
    @include respond(phone-port) {
      border-radius: 0;
    }
  }

  &__quote-icon {
    width: 8rem;
    margin-bottom: 1rem;
  }

  &__text {
    display: block;
    width: 85%;
    line-height: 1.5;
  }

  &__author {
    position: absolute;
    bottom: 4rem;
    left: 4rem;

    @include respond(phone-land) {
      position: static;
      margin-top: 3.5rem;
    }

    &--position {
      color: $color-secondary-light;
    }
  }
}
