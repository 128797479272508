.alert {
  height: 10px;
  color: white;
  position: fixed;
  right: 30px;
  top: 100px;
  padding: 1.5rem 2rem 3rem 2rem;
  letter-spacing: 1px;
  font-size: 1.6rem;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
  opacity: 1;

  @include respond(phone-port) {
    top: 80px;
    right: 20px;
  }
}
