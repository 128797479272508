.training-closer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 10rem;

  @include respond(phone-port) {
    flex-direction: column;
    margin-top: 2rem;
  }

  &__title {
  }

  &__text {
    font-family: $title-font;

    @include respond(phone-port) {
      margin-bottom: 4rem;
    }
  }

  &__image {
    width: 60%;

    @include respond(phone-port) {
      width: 80%;
    }
  }
}
