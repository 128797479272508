.press {
  height: 17vh;
  width: $section-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(tab-land) {
    width: 100%;
  }
  @include respond(phone-land) {
    height: auto;
  }

  &__title {
    font-size: 2rem;
    font-weight: 400;
    color: $color-primary-light;
    margin-bottom: 2rem;
  }

  &__logos-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

    @include respond(tab-land) {
      justify-content: center;
      gap: 4rem;
    }
    @include respond(phone-port) {
      gap: 2rem;
    }
  }

  &__logo {
    width: 20rem;

    @include respond(phone-land) {
      width: 16rem;
    }
    @include respond(phone-port) {
      width: 10rem;
      margin: 0 1rem;
    }
    @include respond(iphone-5) {
      width: 8rem;
    }
  }
}
