.skills-grid {
  margin: 7rem auto;
  width: 80%;

  @include respond(phone-port) {
    width: 100%;
  }

  &__item {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(2, max-content);
    text-align: left;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__icon {
    width: 5rem;
    margin-right: 2.5rem;
  }

  &__text {
    font-weight: $bold;
    font-size: $big-text;
    margin: 0;
  }

  .divider {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
    align-self: flex-start;
    margin-top: 1rem;
    padding: 0;
  }
}
