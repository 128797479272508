.team-card {
  width: $section-width;
  margin: 0 auto;
  background-color: $color-secondary;
  color: $white;
  border-radius: 10px;
  padding: 4rem;
  height: 35rem;
  box-sizing: border-box;
  margin-bottom: 6rem;

  @include respond(tab-land) {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @include respond(phone-land) {
    width: 90%;
    height: max-content;
    padding: 6rem;
  }
  @include respond(phone-port) {
    width: 100%;
    border-radius: 0;
  }

  &__intro {
    color: $white;
    font-size: $big-text;
    margin-bottom: 9rem;
    margin-top: 0;

    @include respond(tab-land) {
      margin-bottom: 0;
    }
  }

  &__text {
    text-align: center;
    line-height: 1.5;
  }
}
