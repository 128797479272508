.grid-item {
  text-align: left;
  width: calc(50% - 5rem);
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 10rem 1fr;
  align-items: center;
  justify-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
  }
  @include respond(phone-port) {
    width: 100%;
  }

  &__icon {
    @include respond(tab-port) {
      margin-bottom: 2rem;
    }
  }

  &__title,
  &__text {
    @include respond(phone-port) {
      width: 90%;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: $small-title;
    margin: 0;

    @include respond(phone-port) {
      margin-bottom: 2rem;
    }
  }

  &__text {
    grid-column: 2 / 3;
    align-self: flex-start;

    a {
      color: $color-secondary;
    }
  }

  &__lead {
    font-weight: bold;
  }
}
