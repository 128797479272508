.support {
  text-align: center;
  margin-bottom: 8rem;

  @include respond(phone-port) {
  }

  &__image {
    @include respond(phone-land) {
      width: 100%;
    }
    @include respond(phone-port) {
      position: relative;
      left: 50%;
      width: 175%;
      transform: translateX(-50%);
      margin-bottom: 1rem;
    }
  }

  &__title {
    margin-top: 10rem;
  }
  &__text {
    min-width: max-content;
    margin-bottom: 6rem;

    @include respond(phone-land) {
      min-width: auto;
    }
  }
}
