.training-grid {
  width: 100%;
  margin: 12rem 0 0 0;

  @include respond(tab-port) {
    margin-top: 0;
  }
  @include respond(iphone-5) {
    width: 100%;
    margin: 0 auto;
  }

  &:last-child {
    background-color: chocolate !important;
  }

  &__item {
    width: $section-width;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 5rem;
    row-gap: 3.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    justify-items: center;
    align-content: center;
    padding: 8rem 0;

    @include respond(tab-land) {
      grid-template-rows: repeat(1, 1fr);
    }
    @include respond(tab-port) {
      display: flex;
      flex-direction: column;
    }
    @include respond(phone-port) {
      width: 80%;
      padding: 0 0 8rem 0;
    }

    &-image {
      grid-row: 1 / 4;
      grid-column: 1 / 2;
      width: 100%;

      @include respond(tab-land) {
        grid-row: 1 / 3;
      }
      @include respond(tab-port) {
        width: 70%;
        margin: 0 auto;
      }
      @include respond(phone-port) {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0;
      }
    }
    &-title {
      align-self: flex-end;
      margin: 0;

      @include respond(phone-land) {
        align-self: center;
      }
      @include respond(phone-port) {
        margin-top: -10rem;
        margin-bottom: 2rem;
      }
    }
    &-text {
      align-self: flex-start;
      margin: 0;

      @include respond(phone-land) {
        align-self: center;
      }
    }
    &-container {
      &:last-child {
        div {
          &:nth-child(1) {
            @include respond(laptop) {
              padding-bottom: 0;
            }
            @include respond(tab-land) {
              padding-bottom: 8rem;
            }
          }
        }
      }
    }
  }
}
