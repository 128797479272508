.navigation {
  display: flex;
  align-items: center;
  height: 10vh;
  width: $section-width;
  margin: 0 auto;
  font-weight: 600;

  @include respond(laptop) {
    width: 80%;
  }

  @include respond(tab-land) {
    height: 15vh;
    width: 90%;
    justify-content: center;
  }
  @include respond(tab-port) {
    display: none;
  }

  &__center-container {
    margin: 0 auto;

    @include respond(tab-port) {
      margin: 0;
    }

    .navigation__item {
      margin: 0 2rem;

      @include respond(big-laptop) {
        margin: 0 1rem;
      }
      @include respond(tab-land) {
        margin: 0 0.5rem;
      }
    }
  }

  &__item {
    text-transform: uppercase;

    &-logo {
      width: 18rem;
      margin-right: 2rem;
      transform: translateY(5px);

      @include respond(tab-land) {
        width: 15rem;
      }
      @include respond(tab-port) {
        margin-right: 0;
      }
    }
    &--active {
      color: $color-secondary;
    }
    &--phone-number {
      margin-right: 2rem;
    }
  }
}

.mobile-navigation {
  display: none;

  @include respond(tab-port) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 3rem;
    color: $color-secondary;
    font-weight: bold;
  }

  &__phone {
    color: $color-secondary;
    font-size: 1.8rem;
  }

  &__phone-container {
    display: flex;
    align-items: center;
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
}
